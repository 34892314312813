.citizencharter {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.nagarik__badapatra,
.barsik__nabrikaran {
    height: 700px;
    overflow: auto;
    position: relative;
}



.nagarik__badapatra thead,
.barsik__nabrikaran thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}



.barsik__nabrikaran table thead,
.nagarik__badapatra table thead {
    background-color: rgb(0, 35, 84)
}

.nagarik__badapatra,
.barsik__nabrikaran {
    border-bottom: 1px solid black;
}

.nagarik__badapatra:hover {
    cursor: pointer;
}

thead {
    color: #fff;
    width: 100%;
}

td{
    padding: 10px;
    border: 1px solid white;
    vertical-align: text-top;
    text-align: left;
}

th {
    text-align: center;
    padding: 10px;
    border: 1px solid white;
    vertical-align: text-top;
}

.nagarik__badapatra thead th,
.barsik__nabrikaran thead th {
    border: 1px solid white;
    
}


td .td__data {
    display: flex;
    align-items: flex-start;
}
td .td__data ol ul{
    list-style-position: inside;
}

tbody {
    width: 100%;
}



.tableWrapper h1 {
    color: #fff;
    padding: 10px;
    font-size: 20px;
    margin-bottom: 0;
    text-align: center;
    background: rgb(4, 36, 82);
    font-weight: bold;
    border: 1px solid white;
}

.grabContaier {
    cursor: grab;
}

tbody tr:nth-child(odd) {
    background-color: rgb(240, 240, 240);
    /* background-color: rgb(226, 228, 230); */
}

tbody tr:nth-child(even) {
    /* background-color: rgb(142, 160, 175); */
    background-color: rgb(232, 230, 230);
}



.barsik__nabrikaran,
.barsik__nabrikaran table,
.nagarik__badapatra,
.nagarik__badapatra table {
    width: calc(100vw - 20px);
    max-width: 1550px;
}



@media only screen and (max-width: 1200px) {
    .citizencharter {
        flex-direction: column;
        align-items: center;
    }

    .tableWrapper {
        margin: 0 10px;
    }

    .barsik__nabrikaran,
    .barsik__nabrikaran table,
    .nagarik__badapatra,
    .nagarik__badapatra table {
        width: calc(100vw - 20px);
    }
}



.searchBox {
    display: flex;
    align-items: center;
    justify-content: right;
    margin: 5px;
}

.searchWrapper {
    border: 1px solid rgb(160, 160, 160);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    padding-left: 5px;
    border-radius: 10px;

}

.searchWrapper input {
    height: 100%;
    margin: 0;
    border: none;
    outline: none;
}


.searchWrapper>button {
    margin: 0;
    border: none;
    background-color: #fff;
}

@media only screen and (max-width: 830px) {
    .searchBox {
        justify-content: center;
    }
}