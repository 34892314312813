.modal__wrapper{
    width: 100vw;
    background: #0000002a;
    padding: 20px;
    height: 100vh;
}

.modal__container{
    background: #fff;
    margin: auto;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.1);
    border-radius: 5px;
    max-width: 1000px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    height: 100%;
}

.modal__upper{
    width: 100%;
    max-width: 718.317px;
}
.modal__heading{
    width: 100%;
}
.cancel__btn{
    font-size: large;
    font-size: 35px;
    font-weight: 100;
    cursor: pointer;
}