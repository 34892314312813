$primary: rgb(0 65 158 / 100%);
$secondary: rgb(184 40 44 / 100%);

@import "../../../../../node_modules/bootstrap/scss/bootstrap.scss";

:root {
  --primary: rgb(0 65 158 / 100%);
  --secondary: rgb(184 40 44 / 100%);
}

#main-header {
  position: relative;
  background-color: rgb(0 65 158 / 90%);
}

#main-header .nav-item .nav-link {
  font-weight: 400;
  padding: 0.5rem;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  font-size: 1rem !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

#main-header::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../public/images/banner/banner.jpg");
  background-position: center center;
  background-size: cover;
  z-index: -1;
}

#search-banner {
  padding: 0px;
}

#search-banner input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  font: inherit;
  color: white;
  padding: 0.5rem;
  width: 180px;
  border-radius: 8px !important;
}

#search-banner input::placeholder {
  font: inherit;
  color: rgba(255, 255, 255, 0.4);
}

#search-banner svg {
  color: rgba(255, 255, 255, 0.4) !important;
}

#search-banner button {
  background-color: transparent !important;
  position: absolute;
  top: 0;
  right: 0px;
  border: none !important;
  margin: 0px;
  padding: 9px;
  padding-right: 12px;
}

/* Banner - Carousel */

.carousel-inner .carousel-item img {
  height: 420px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

/* Banner Start */
#banner-news {
  color: white;
  padding-bottom: 1rem;
  background-color: var(--primary);
}

#banner-news .nav {
  padding-top: 5px;
  border-bottom: none;
}

#banner-news .nav .nav-item {
  flex: 1;
  text-align: center;
}

#banner-news .nav .nav-item a {
  background-color: inherit;
  border: none;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  border-bottom: 2px solid white;
}

#banner-news .nav .nav-item a.active {
  font-weight: bold;
  border-bottom: 2px solid red;
}

#banner-news .tab-content {
  height: 343px;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow-y: auto;
}

#banner-news .tab-content ul li {
  background-color: transparent;
  color: white;
  margin-bottom: 10px;
  border: 0px;
  font-size: 19px;
  list-style-type: circle;
}

#banner-news .tab-content::-webkit-scrollbar {
  display: none;
}

#munCarousel .carousel-indicators {
  display: none;
}

#munCarousel .carousel-control-prev-icon,
#munCarousel .carousel-control-next-icon {
  position: relative;
}

#munCarousel .carousel-control-prev-icon::after,
#munCarousel .carousel-control-next-icon::after {
  content: "";
  position: absolute;
  top: -8px;
  width: 100%;
  height: 100%;
  background: black;
  border-radius: 50%;
  left: -8px;
  z-index: -1;
  width: 50px;
  height: 50px;
}

#munCarousel .carousel-item .carousel-caption {
  bottom: 0px;
  left: 0px;
  right: unset;
  top: unset;
  text-align: left;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: #b8282cd1 !important;
}

#main-nav .navbar-nav .nav-item a {
  padding: 10px 25px !important;
  color: var(--primary);
}

#main-nav .navbar-nav .nav-item a svg {
  color: var(--primary);
}

#departments .card a {
  text-decoration: none;
}

#departments .card:hover {
  background-repeat: no-repeat;
  background-position: -120px -120px, 0 0;
  background-image: -webkit-linear-gradient(
    top left,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 37%,
    rgba(255, 255, 255, 0.8) 45%,
    rgba(255, 255, 255, 0) 50%
  );
  background-image: -moz-linear-gradient(
    0 0,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 37%,
    rgba(255, 255, 255, 0.8) 45%,
    rgba(255, 255, 255, 0) 50%
  );
  background-image: -o-linear-gradient(
    0 0,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 37%,
    rgba(255, 255, 255, 0.8) 45%,
    rgba(255, 255, 255, 0) 50%
  );
  background-image: linear-gradient(
    0 0,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 37%,
    rgba(255, 255, 255, 0.8) 45%,
    rgba(255, 255, 255, 0) 50%
  );

  -moz-background-size: 250% 250%, 100% 100%;
  background-size: 250% 250%, 100% 100%;

  -webkit-transition: background-position 0s ease;
  -moz-transition: background-position 0s ease;
  -o-transition: background-position 0s ease;
  transition: background-position 0s ease;
}

#departments .card:hover {
  background-position: 0 0, 0 0;

  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

#gallery .card img {
  height: 220px;
  object-fit: cover;
  object-position: center center;
}

// .shine {
//   overflow: hidden;
//   position: relative;

//   &::before {
//     content: "";
//     background: rgba(white, 0.4);
//     box-shadow: 0px 0px 0px 40px rgba(white, 0.2);
//     width: 60%;
//     height: 100%;
//     top: 0%;
//     left: -125%;
//     transform: skew(45deg);
//     position: absolute;
//     transition: inherit;
//     z-index: 999;
//     transition: 0.8s all ease;
//     animation-name: shine;
//     animation-duration: 5s;
//     animation-delay: 2s;
//     animation-iteration-count: infinite;
//   }

//   // &:hover::before {
//   //   left: 160%;
//   // }

//   @keyframes shine {
//     from {
//       left: -125%;
//     }
//     to {
//       left: 160%;
//     }
//   }
// }

#team {
  padding: 20px 0px;
  background: $gray-200;

  .col-12 {
    perspective: 1200px;
  }

  .card {
    transition: 0.15s all ease;
    cursor: pointer;
    border-radius: 16px;

    img {
      border-radius: 16px;
    }

    &:hover {
      transform: translateY(-5px);

      .name {
        color: var(--secondary);
      }

      .title,
      .name {
        font-weight: bold;
      }
    }
  }
}

#team .card {
  text-align: center;
  padding: 10px;
}

#team .card img {
  width: 100%;
  height: 420px;
  object-fit: cover;
  object-position: center top;
  margin-bottom: 15px;
}

.owl-carousel {
  max-width: 100%;
  margin: 0 auto;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
}

.owl-nav button span {
  font-size: 26px;
  height: 100%;
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav button.owl-prev {
  left: 0;
}
.owl-carousel .owl-nav button.owl-next {
  right: 0;
}
.owl-carousel .owl-nav {
  margin: 0;
}
.btn-wrap {
  text-align: center;
  width: 100%;
}
button {
  background-color: #ddd;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.5s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.owl-theme .owl-nav .disabled,
button.disabled {
  opacity: 0.6;
}
