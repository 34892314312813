.tax__wrapper{
    margin: 20px 0px;
}

.tax__container{
    max-width: 1600px;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
}


/* text left */
.tax__left{
    flex: 1.2;
    padding: 20px;
}

.tax__right-lower table td{
    text-align: center;
}

.married__wrapper, .income__wrapper, .deduction__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.married__wrapper label, .income__wrapper label, .deduction__wrapper label{
    justify-self: flex-start;
    width: 100%;
}

.input__wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.input__container{
    display: flex;
    width: 100%;
    margin: 10px 0;
    gap: 10px;
}

.select__input{
    flex: 1;
    height: 34px;
    outline: none;
    border: 1px solid rgb(189, 183, 183);;
    background-color: #fff;
    color: gray;

}

.checkbox__input{
    width: 180px;
    height: 34px;
    display: flex;
    align-items: center;
}
.checkbox__input input{
    height: 20px;
    width: 20px;
    margin-left: 10px;
}


.input__container .text__input{
    width: 100%;
    display: flex;
    flex: 1;
    border: 1px solid rgb(189, 183, 183);
}

.input__container .text__input .input__icon-wrapper{
   background: rgb(221, 218, 218);
   height: 100%;
   width: 35px;
   font-size: larger;
   display: flex;
   align-items: center;
   justify-content: center;
}

.input__container .text__input input{
    flex: 1;
    padding: 5px 5px 5px 10px;
    outline: none;
    border: none;
    color: gray;
}


.detail__icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.detail__icon-wrapper:hover{
    cursor: pointer;
}

.tax__left h5{
    color: blue;
}

.tax__left p{
    color: gray;
    font-size: 13px;
}

.buttons{
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.buttons button{
    width: 100%;
    margin: 10px 0;
    font-weight: bold;
}

.calculate-btn{
    background: rgb(91, 91, 197);
    color: white;

}

.reset-btn{
    border: 1px solid black;
    outline: none;
    color: black;
}



/* tax right */
.tax__right{
    flex: 1;
    padding: 20px;
   
}

.tax__right-upper{
    padding: 20px;

}

.tax__right-lower{
    padding: 20px;
   
}

.tax__card{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    margin: 50px 0;
}

.tax__card-up{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.tax__card-up h5{
    font-size: 16px;
    margin: 0;
}

.tax__card-down{

}
.tax__card-down h1{
    /* color: rgb(38, 185, 38); */
    color: blue;

}

.tax__right-lower{
    padding: 20px;
}

.tax__right-lower table{
    /* border-bottom: 1px solid black; */
    width: 100%;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.1);
}

.tax__right-lower table td, .tax__right-lower table th{
    border: none;
    /* border-top: 1px solid black; */
}

.tax__liability{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin: 20px 0px;
    border-top: 1px solid black;
}

.tax__liability h5{
    font-size: 13px;
}
.new__income__button button{
    color: #fff;
    font-weight: 400;
    background-color: blue;
}
